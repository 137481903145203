<template>
<div style="width:100%;height:100%; margin:0 auto;">
  <div class="app-container">
      <div id="app-contain">
    <div class="filter-container" id="filter-container">
      <!-- <div style="float:right">
      <el-select v-model="queryEntry.schoolType" placeholder="学校类型" style="width: 200px;" class="filter-item" clearable>
    <el-option
      v-for="item in schoolType"
      :key="item.code"
      :label="item.name"
      :value="item.code"
      >
    </el-option>
  </el-select>
  
     <el-date-picker  v-model="queryEntry.stYear"  type="year" style="width: 200px;" class="filter-item" value-format="yyyy-MM-dd HH:mm:ss"  placeholder="选择任务年份"> 
              </el-date-picker>
               
   
             
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="addReportItem">
        报表生成
      </el-button>
      </div> -->
      <div style="float:left">
         <el-select v-model="logQuery.departmentId" placeholder="区县" style="width: 200px;" class="filter-item" clearable>
    <el-option
      v-for="item in districtType"
      :key="item.id"
      :label="item.name"
      :value="item.id"
      >
    </el-option>
  </el-select>
         <el-select v-model="logQuery.schoolType" placeholder="学校类型" style="width: 200px;" class="filter-item" clearable>
    <el-option
      v-for="item in schoolType"
      :key="item.code"
      :label="item.name"
      :value="item.code"
      >
    </el-option>
  </el-select>
  
     <el-date-picker  v-model="logQuery.stYear"  type="year" style="width: 200px;" class="filter-item" value-format="yyyy-MM-dd HH:mm:ss"  placeholder="选择任务年份"> 
              </el-date-picker>
               
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="loadList">
        查询
      </el-button>
      </div>
    </div>
    <div style="width:100%;height:100%;">
    
    <el-table
      v-loading="listLoading"
      :data="tablelist"
      height="90%"
      border
      fit
      highlight-current-row
      style="width: 100%;overflow:auto"
    >
     <el-table-column label="序号" :index="indexMethod" type="index" align="center" width="50x" />
            <el-table-column label="区县" header-align="center" align="left" prop="deptName"  width="100"/>
            <el-table-column label="名称" header-align="center" align="left" prop="riName"  min-width="150"/>
               <el-table-column label="报表任务状态" header-align="center" align="left" width="160">
              <template slot-scope="scope">
                <span>{{ getDicName(scope.row.riState,'YW_BBRW') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="任务年度" header-align="center" align="left" prop="stYear" min-width="60" />
            <!-- <el-table-column label="版本" header-align="center" align="left" prop="edition" min-width="50" /> -->
             <el-table-column label="学校类型" header-align="center" align="left" width="100">
              <template slot-scope="scope">
                <span>{{ getDicName(scope.row.schoolType,'TXPE_OF_CAMOUS') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="开始时间" header-align="center" align="right" prop="riBeginTime" width="150" show-overflow-tooltip />
            <el-table-column label="结束时间" header-align="center" align="right" prop="riEndTime" width="150" show-overflow-tooltip />
             <el-table-column
        fixed="right"
        label="操作"
        width="200"
        header-align="center"
        align="left"
      >
        <template slot-scope="scope">
          <span>
            
            <el-button  type="primary" v-if="scope.row.riState=='3'" size="small" @click="bbView(scope.row)">查看</el-button>

          </span>
        </template>
      </el-table-column>
    </el-table>
    <pagination id="pagination" v-show="total>0" :total="total" :page.sync="logQuery.page" :limit.sync="logQuery.limit" @pagination="loadList" />
      <el-dialog :close-on-click-modal="false" :visible.sync="menuDyxmdVisible" append-to-body width="1200px" title="学校">
      <school-summaryVue v-if="menuDyxmdVisible" :proid="code" :tableType="code2" :visible.sync="menuDyxmdVisible" />
    </el-dialog>
      </div>
      </div>
       
  </div>
 
</div>
</template>

<script>
// import { page, submitTest, release, revoke ,getDeployTreeList} from '@/api/api-deploy'
import { getDictListByCode } from '@/api/dict'
import Pagination from '@/components/Pagination'
import { tableHeight2 } from '@/utils/tableHeight'
import { Message, MessageBox } from 'element-ui'
import{addLog} from '@/api/sysReportLog'
import { addReportItem ,getTablePage} from '@/api/report'
import SchoolSummaryVue from './schoolSummary.vue'
import { getDistricDeptId } from '@/api/organization'

export default {
  components: { Pagination,SchoolSummaryVue},
  mixins: [tableHeight2],
  props: { // 第二种方式
    proid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filterText:null,
      list: null,
      tablelist:[],
      schoolType:[],
      districtType:[],
      queryEntry:{},
      total: 0,
      code:'',
      code2:1,
      listLoading: false,
      menuDyxmdVisible:false,
      treeLoading:false,
      //任务页面
      asideAssignment:true,
      //表单页面
      asideTable:true,
      //1
      dialogVisibleBI:false,
      //2
      dialogVisibleLMP:false,
      //3
      dialogVisibleCU:false,
      //4
      dialogVisibleTI:false,
      //5
      dialogVisibleTE:false,
      //6
      dialogVisibleSEA:false,
      //7
      dialogVisibleLA:false,
      //8
      dialogVisibleLS:false,
      //9
      dialogVisibleEI:false,
      //审批意见
      dialogVisibleEXA:false,
      exajg:'',
      radio:null,
      deptId:'',
      listQuery: {
        page: 1,
        limit: 10,
        name: null,
        type: null,
        itemId: this.$getCookie('projectId')
      },
      
      logQuery:{
        limit:10,
        page:1,
        id:null
      },
      apiTypes: [],
      treeList:[],
      defaultProps: {
        children: 'children',
        label: 'srTitle'
      },
      tabheight:0,
      logList:[],
      apiDeployStates: [],
      dialogVisibleAdd: false,
      titel: '',
      form: {},
      srTaskSchoolId:'',
      rules: {}
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    }
  },
   mounted(){
     this.$nextTick(function() {
      var filterHeight = document.getElementById('filter-container').offsetHeight
      var tableHeight = document.getElementById('app-contain').offsetHeight
      var pagination = document.getElementById('pagination').offsetHeight
      this.tabheight = tableHeight - filterHeight - pagination-15
      console.log(this.tabheight,filterHeight,tableHeight,pagination)
      window.onresize = () => {
        return (() => {
          var filterHeight = document.getElementById('filter-container').offsetHeight
          var tableHeight = document.getElementById('app-contain').offsetHeight
          var pagination = document.getElementById('pagination').offsetHeight
          this.tabheight = tableHeight - filterHeight - pagination-15
        })()
      }
    })
  },
  created() {
    this.loadList()
    //this.getTableTreeList();
    this.getDictList('YW_BBRW')
    this.getDictList('TXPE_OF_CAMOUS')
    this.getDistricDeptId()
  },
  methods: {

     indexMethod(index) {
      return (index + 1) + (this.logQuery.page - 1) * this.logQuery.limit
    },
    getDistricDeptId(){
      getDistricDeptId().then(response =>{
        this.districtType=response.data
      })
    },
    getDictList(code) {
      getDictListByCode(code).then(response => {
        if (code === 'YW_BBRW') {
          this.apiDeployStates = response.data
          
        }
         if (code === 'TXPE_OF_CAMOUS') {
          this.schoolType = response.data
          
        }
      })
    },
    addReportItem() {
        if(!this.queryEntry.stYear){
           Message({
            message: '请选择任务年度',
            type: 'error',
            duration: 5 * 1000
          })
         
          return 
        }
      if(!this.queryEntry.schoolType){
           Message({
            message: '请选择学校类型',
            type: 'error',
            duration: 5 * 1000
          })
         
          return 
        }
      addReportItem(this.queryEntry).then(response => {
        this.loadList()
      })
    },
    getDicName(code, flag) {
      var dict = []
      if (flag === 'YW_BBRW') {
        dict = this.apiDeployStates
      }
       if (flag === 'TXPE_OF_CAMOUS') {
        dict = this.schoolType
      }
      for (var i in dict) {
        if (dict[i].code === code) {
          return dict[i].name
        }
      }
    },
    bbView(e) {
      this.menuDyxmdVisible = true
      this.code = e.riPkId
      this.code2 =1
      console.log(this.code)
      this.$nextTick(() => {
        this.$refs.menuDyxmdVisible
        // this.$refs.MenuDy.getDictEntry()
      })
    },
    submit(state){
      var query={}
      query.stsId=this.proid
      query.state=state
      
      if(this.exajg==null){
         Message({
            message: '审批意见不能为空',
            type: 'error',
            duration: 5 * 1000
          })
          return
      }
      query.logText=this.exajg
      addLog(query).then(response=>{
         Message({
            message: '审批成功',
            type: 'success',
            duration: 5 * 1000
          })
           this.$emit('update:visible', false)
      })
    },
    // //操作日志
    // handleLog(data){
    //   this.dialogVisibleLog=true
    //   this.logQuery.id=data.id;
    //   this.geLogList();
    // },
    //  geLogList(){
    //   this.logLoading=true
    //   getLogByDevelopment(this.logQuery).then(response=>{
    //     this.logLoading=false
    //     this.logList=response.data.records;
    //     this.logTotal=parseInt(response.data.total)
    //   }).catch(error=>{
    //     this.logLoading=false
    //   })
    // },
    // //节点过滤
    handleNodeClick(data){
  
      
    },
    // //节点过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.srTitle.indexOf(value) !== -1;
    },
    // handleFilter() {
    //   this.listQuery.page = 1
    //   this.loadList()
    // },
    loadList() {
      
      
        this.listLoading = true
      getTablePage(this.logQuery).then(response => {
        
        this.listLoading = false
        this.tablelist = response.data.records
         this.total = parseInt(response.data.total)
        
        // this.treeList= [response.data.treeList]
        //this.treeList.push({srTitle:"审批意见",level:2,children:[]})
        

        //this.total = parseInt(this.tablelist.length)
      }).catch(response => {
        this.listLoading = false
      })
    },
    // handleAdd() {
    //   this.$router.push({ path: './arrange/arrangeAdd' })
    // },
    handleEdit(row) {
      if(row.type===1){
        this.dialogVisibleBI=true
        this.$nextTick(() => {
         this.$refs.basicInformation
        })
      }
      else if(row.type===2){
        this.dialogVisibleLMP=true
        this.$nextTick(() => {
         this.$refs.laboratorymanagementpersonnel
        })
      }
      else if(row.type===3){
        this.dialogVisibleCU=true
        this.$nextTick(() => {
         this.$refs.classroomusage
        })
      }
      else if(row.type===4){
        this.dialogVisibleTI=true
        this.$nextTick(() => {
         this.$refs.teachinginstrument
        })
      }
      else if(row.type===5){
        this.dialogVisibleTE=true
        this.$nextTick(() => {
         this.$refs.teachingequipment
        })
      }
      else if(row.type===6){
        this.dialogVisibleSEA=true
        this.$nextTick(() => {
         this.$refs.scienceexperimentactual
        })
      }
      else if(row.type===7){
        this.dialogVisibleLA=true
        this.$nextTick(() => {
         this.$refs.libraryadministrators
        })
      }
      else if(row.type===8){
        this.dialogVisibleLS=true
        this.$nextTick(() => {
         this.$refs.librarysituation
        })
      }
      else if(row.type===9){
        this.dialogVisibleEI=true
        this.$nextTick(() => {
         this.$refs.educationalinvestment
        })
      }

          
    },
    // handleSubmitTest(row) {
    //   if (row.state !== '1' && row.state !== '5') {
    //     this.$router.push({ path: './arrange/test', query: { id: row.id }})
    //   } else {
    //     MessageBox.confirm('确定提交测试吗', '确定', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       cancelButtonClass: 'qx',
    //       type: 'warning'
    //     }).then(() => {
    //       const loading = this.$loading({
    //         lock: true,
    //         text: 'Loading',
    //         spinner: 'el-icon-loading',
    //         background: 'rgba(0, 0, 0, 0.7)'
    //       })

    //       submitTest(row.id).then(response => {
    //         loading.close()
    //         this.$router.push({ path: './arrange/test', query: { id: row.id }})
    //       }).catch(function() {
    //         loading.close()
    //       })
    //     })
    //   }
    // },
    // handleRelease(row) {
    //   MessageBox.confirm('确定发布吗', '确定', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     cancelButtonClass: 'qx',
    //     type: 'warning'
    //   }).then(() => {
    //     const loading = this.$loading({
    //       lock: true,
    //       text: 'Loading',
    //       spinner: 'el-icon-loading',
    //       background: 'rgba(0, 0, 0, 0.7)'
    //     })

    //     release(row.id).then(response => {
    //       Message({
    //         message: '操作成功',
    //         type: 'success',
    //         duration: 5 * 1000
    //       })
    //       loading.close()
    //       this.handleFilter()
    //     }).catch(function() {
    //       loading.close()
    //     })
    //   })
    // },
    //树型结构
    getTableTreeList(){
      this.treeLoading=true;
      this.treeList=[{
        name:"22年教育装备填报",level:1,type:0,children:[
          {name:"表1 学校基本情况统计表",level:2,type:1,children:[]},
          {name:"表2 实验室管理人员情况统计表",level:2,type:2,children:[]},
          {name:"表3 实验室及功能教室用房情况统计表",level:2,type:3,children:[]},
          {name:"表4 教学仪器情况统计表",level:2,type:4,children:[]},
          {name:"表5 教学设备情况统计表",level:2,type:5,children:[]},
          {name:"表6 理科实验开出情况统计表",level:2,type:6,children:[]},
          {name:"表7 图书管理人员情况统计表",level:2,type:7,children:[]},
          {name:"表8 图书馆（室）情况统计表",level:2,type:8,children:[]},
          {name:"表9 当年教育技术装备投入情况统计表",level:2,type:9,children:[]}
         
        ]
      },
      {
        name:"审批意见",level:1,type:1,children:[]
      }]
      this.treeLoading=false;
    },
   
  }
}
</script>
<style lang="less" scoped>
.app-container{
  padding:10px 20px;
  height: 100%;
}
.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .date-filter-item{
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
  margin-top:0 !important;
  padding: 10px 16px !important;
}
#app-contain{
    height:100%;
    width:100%;
  }
</style>
